import {
  faFacebookF,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { faCopy, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope as faEnvelopeSolid } from '@fortawesome/free-solid-svg-icons';
import {
  getEmailReferUrl,
  getEmailShareUrl,
  getFacebookReferUrl,
  getFacebookShareUrl,
  getTwitterReferUrl,
  getTwitterShareUrl,
  getWhatsAppReferUrl,
  getWhatsAppShareUrl,
} from '../../helpers/share';
import {
  SHARE_ON_FACEBOOK,
  SHARE_ON_TWITTER,
  SHARE_ON_WHATSAPP,
  SHARE_BY_EMAIL,
  COPY,
} from '../text/text';

export const FACEBOOK_ID = {
  dev: {
    livingsocial: '1202473109784491',
    livingsocialie: '1202473109784491',
    wowcher: '1119130994873669',
  },
  nxt: {
    livingsocial: '1202473109784491',
    livingsocialie: '1202473109784491',
    wowcher: '1374538939489353',
    wowcherie: '1374538939489353',
  },
  prod: {
    livingsocial: '932992040130735',
    livingsocialie: '932992040130735',
    wowcher: '396501950453618',
    wowcherie: '396501950453618',
  },
};

const googleIDLS =
  '34042195811-9oqe1pat6pgbt0dg63i08rfh8bb4gdgt.apps.googleusercontent.com';
const googleIDWoW =
  '1038958465818-bifi7him12g9ndik4etlg1n8h2ga7ean.apps.googleusercontent.com';

export const GOOGLE_ID = {
  dev: {
    livingsocial:
      '400976847728-qhatpmmgk6qikqnv881pjh4qglpbfkuv.apps.googleusercontent.com',
    livingsocialie:
      '400976847728-qhatpmmgk6qikqnv881pjh4qglpbfkuv.apps.googleusercontent.com',
    wowcher:
      '1003252758086-oje528jjbj6hcooempislk6astiaqij5.apps.googleusercontent.com',
    wowcherie:
      '1003252758086-oje528jjbj6hcooempislk6astiaqij5.apps.googleusercontent.com',
  },
  nxt: {
    livingsocial: googleIDLS,
    livingsocialie: googleIDLS,
    wowcher: googleIDWoW,
    wowcherie: googleIDWoW,
  },
  prod: {
    livingsocial: googleIDLS,
    livingsocialie: googleIDLS,
    wowcher: googleIDWoW,
    wowcherie: googleIDWoW,
  },
};

export const TWITTER_HANDLES = {
  livingsocial: '@LivingSocialUK',
  livingsocialie: '@LivingSocialIE',
  wowcher: '@Wowcher',
  wowcherie: '@WowcherIE', // TODO: Change twitter handle
};

export const WEB_ADDRESS = {
  livingsocial: 'LivingSocial.co.uk',
  livingsocialie: 'LivingSocial.ie',
  wowcher: 'Wowcher.co.uk',
  wowcherie: 'Wowcher.ie',
};

export const FACEBOOK_SHARE_ITO = {
  livingsocial: 'LSUK_social_SCM00003_SHARE_',
  livingsocialie: 'LSIE_social_SCM00003_SHARE_',
  wowcher: 'wowcher_social_SCM00003_SHARE_',
  wowcherie: 'wowcher_social_SCM00004_SHARE_', // TODO: Change share handle
};

export const TWITTER_SHARE_ITO = {
  livingsocial: 'LSUK_social_SCM00004_SHARE_',
  livingsocialie: 'LSIE_social_SCM00004_SHARE_',
  wowcher: 'wowcher_social_SCM00004_SHARE_',
  wowcherie: 'wowcher_social_SCM00004_SHARE_', // TODO: Change share handle
};

export const WHATSAPP_SHARE_ITO = {
  livingsocial: 'LSUK_social_SCM00005_SHARE_',
  livingsocialie: 'LSIE_social_SCM00005_SHARE_',
  wowcher: 'wowcher_social_SCM00005_SHARE_',
  wowcherie: 'wowcher_social_SCM00004_SHARE_', // TODO: Change share handle
};

export const EMAIL_SHARE_ITO = {
  livingsocial: 'LSUK_email_SHARE_SHARE_',
  livingsocialie: 'LSIE_email_SHARE_SHARE_',
  wowcher: 'wowcher_email_SHARE_SHARE_',
  wowcherie: 'wowcher_social_SCM00004_SHARE_', // TODO: Change share handle
};

export const FACEBOOK_RAF_ITO = 'raf_facebook';
export const WHATSAPP_RAF_ITO = 'raf_whatsapp';
export const TWITTER_RAF_ITO = 'raf_twitter';
export const EMAIL_RAF_ITO = 'raf_email';

export const getMainDealShareButtons = (deal) => [
  {
    ariaLabel: `${SHARE_ON_FACEBOOK}`,
    backgroundColor: '#0052a3',
    href: getFacebookShareUrl(deal, FACEBOOK_SHARE_ITO, FACEBOOK_ID),
    icon: faFacebookF,
    key: 'facebook',
    popup: true,
  },
  {
    ariaLabel: `${SHARE_ON_TWITTER}`,
    backgroundColor: '#4099ff',
    href: getTwitterShareUrl(deal, TWITTER_SHARE_ITO, TWITTER_HANDLES),
    icon: faTwitter,
    key: 'twitter',
    popup: true,
  },
  {
    ariaLabel: `${SHARE_ON_WHATSAPP}`,
    backgroundColor: '#25D366',
    href: getWhatsAppShareUrl(deal, WHATSAPP_SHARE_ITO, WEB_ADDRESS),
    icon: faWhatsapp,
    key: 'whatsapp',
    popup: true,
  },
  {
    ariaLabel: `${SHARE_BY_EMAIL}`,
    backgroundColor: '#cccccc',
    href: getEmailShareUrl(deal, EMAIL_SHARE_ITO, WEB_ADDRESS),
    icon: faEnvelope,
    key: 'email',
    popup: true,
  },
];

export const getReferFriendShareButtons = (referralLink) => [
  {
    ariaLabel: `${COPY}`,
    backgroundColor: '#EA008C',
    icon: faCopy,
    key: 'copy',
    popup: false,
  },
  {
    ariaLabel: `${SHARE_ON_WHATSAPP}`,
    backgroundColor: '#25D366',
    href: getWhatsAppReferUrl(referralLink, WHATSAPP_RAF_ITO),
    icon: faWhatsapp,
    key: 'whatsapp',
    popup: true,
  },
  {
    ariaLabel: `${SHARE_ON_FACEBOOK}`,
    backgroundColor: '#0052a3',
    href: getFacebookReferUrl(referralLink, FACEBOOK_ID, FACEBOOK_RAF_ITO),
    icon: faFacebookF,
    key: 'facebook',
    popup: true,
  },
  {
    ariaLabel: `${SHARE_ON_TWITTER}`,
    backgroundColor: '#4099ff',
    href: getTwitterReferUrl(referralLink, TWITTER_RAF_ITO),
    icon: faTwitter,
    key: 'twitter',
    popup: true,
  },
  {
    ariaLabel: `${SHARE_BY_EMAIL}`,
    backgroundColor: '#cccccc',
    href: getEmailReferUrl(referralLink, EMAIL_RAF_ITO),
    icon: faEnvelopeSolid,
    key: 'email',
    popup: false,
  },
];
